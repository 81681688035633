import { states } from '../utils/helpers'
import { timeAgo } from '../utils/dateHelpers'
import { z } from 'zod'
import {
  CURRENT_AUTH_LETTER_VERSION,
  ProfileFields,
} from '../redux/profile/ProfileSlice'
import { phoneNumberSchema } from './util'

export const createAddressSchema = (
  isStreetOptional: boolean,
  isZipOptional: boolean,
) => {
  const addressSchema = z.object({
    city: z
      .string()
      .nonempty('City is required')
      .max(100, 'City cannot be longer than 100 characters'),
    state: z.enum(states, {
      errorMap: () => ({
        message: 'Must be a valid 2 letter state code',
      }),
    }),
    streetAddress: z
      .string()
      .max(100, 'Street cannot be longer than 100 characters')
      .refine((value) => isStreetOptional || value.length > 0, {
        message: 'Street is required',
      }),
    zip: isZipOptional
      ? z
          .string()
          .max(10, 'Zip Code cannot be longer than 10 characters')
          .optional()
          .refine((value) => !value || value.trim().length > 0, {
            message: 'Zip code cannot be just spaces',
          })
          .refine((value) => !value || /^\d+$/.test(value), {
            message: 'Zip code must contain only digits',
          })
      : z
          .string()
          .max(10, 'Zip Code cannot be longer than 10 characters')
          .refine((value) => value.length > 0, {
            message: 'Zip is required',
          })
          .refine((value) => value.trim().length > 0, {
            message: 'Zip code cannot be just spaces',
          })
          .refine((value) => /^\d+$/.test(value), {
            message: 'Zip code must contain only digits',
          }),
  })
  return addressSchema.array()
}
const addressSchema = z.object({
  city: z
    .string()
    .nonempty('City is required')
    .max(100, 'City cannot be longer than 100 characters'),
  state: z.enum(states, {
    errorMap: () => ({
      message: 'Must be a valid 2 letter state code',
    }),
  }),
  streetAddress: z
    .string()
    .max(100, 'Street cannot be longer than 100 characters'),
  zip: z
    .string()
    .max(10, 'Zip code cannot be longer than 10 characters')
    .optional()
    .or(z.literal('')),
})

export const registerSchema = z.object({
  firstName: z
    .string()
    .nonempty('First name is required')
    .max(50, 'Name cannot be more than 50 characters')
    .regex(
      /^[a-zA-Z-]+$/,
      'First name can only contain letters and hyphens (-)',
    ),
  middleName: z
    .string()
    .max(50, 'Name cannot be more than 50 characters')
    .regex(
      /^[a-zA-Z-]+$/,
      'Middle name can only contain letters and hyphens (-)',
    ),
  lastName: z
    .string()
    .nonempty('Last name is required')
    .max(50, 'Name cannot be more than 50 characters')
    .regex(
      /^[a-zA-Z-]+$/,
      'Last name can only contain letters and hyphens (-)',
    ),
  city: z
    .string()
    .nonempty('City is required')
    .max(100, 'City cannot be longer than 100 characters'),
  state: z.enum(states, {
    errorMap: () => ({
      message: 'Must be a valid 2 letter state code',
    }),
  }),
  streetAddress: z.string(),
  zip: z.string(),
  email: z.string().email().or(z.literal('')),
  phoneNumber: phoneNumberSchema,
  birthdate: z.coerce
    .date()
    .max(
      timeAgo({ years: 18 }),
      'You must be at least 18 years old to use mePrism',
    )
    .min(
      timeAgo({ years: 100 }),
      'You must be under 100 years old to use mePrism',
    )
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select a valid birthdate',
    }),
  linkedIn: z
    .string()
    .trim()
    .url('Invalid LinkedIn URL')
    .max(120, 'LinkedIn URL cannot exceed 120 characters')
    .regex(
      /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/,
      'LinkedIn URL must be a valid LinkedIn profile link',
    )
    .optional()
    .or(z.literal('')),
})
// .superRefine(({ noMiddleName, middleName }, ctx) => {
//   if (!middleName && !noMiddleName) {
//     ctx.addIssue({
//       path: ['middleName'],
//       code: 'custom',
//       message:
//         'You must provide a middle name/initial, or indicate you do not have one.',
//     })
//   }
// })

// React Hook Form only permits Object types when used in dynamic forms
// hence we represent all our names as objects

// Define a function to create the schema with dynamic `middleName` requirement
export const createProfileSchema = (
  isMiddleNameOptional: boolean,
  isLinkedinOptional: boolean,
  isStreetOptional: boolean,
  agreeToTermsOptional: boolean,
  isZipOptional: boolean,
) => {
  const profileSchema = z.object({
    firstName: z
      .object({
        value: z
          .string()
          // .nonempty('First name is required')
          .max(50, 'Name cannot be more than 50 characters')
          .regex(
            /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
            'First name can only contain letters and hyphens (-)',
          ),
      })
      .array(),
    // .optional(),

    // Use the conditional `middleNameSchema` with or without `.optional()`
    middleName: z
      .object({
        value: z
          .string()
          .max(50, 'Name cannot be more than 50 characters')
          .regex(
            /^(?:[a-zA-Z]+(?:[\s-][a-zA-Z]+)*)?$/,
            'Middle name can only contain letters and hyphens (-)',
          )
          .refine((value) => isMiddleNameOptional || value.length > 0, {
            message: 'Middle name is required',
          }),
      })
      .array(),
    lastName: z
      .object({
        value: z
          .string()
          // .nonempty('Last name is required')
          .max(50, 'Name cannot be more than 50 characters')
          .regex(
            /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
            'Last name can only contain letters and hyphens (-)',
          ),
      })
      .array(),
    // .optional(),

    otherFirstName: z
      .object({
        value: z
          .string()
          .max(50, 'Name cannot be more than 50 characters')
          .regex(
            /^$|^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
            'First Name can only contain letters and hyphens (-)',
          )
          .optional(),
      })
      .array()
      .optional(),

    otherMaidenName: z
      .object({
        value: z
          .string()
          // .nonempty('Last name is required')
          .max(50, 'Name cannot be more than 50 characters')
          .regex(
            /^$|^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
            'Maiden name can only contain letters and hyphens (-)',
          )
          .optional(),
      })
      .array()
      .optional(),

    phoneNumber: z.object({ value: phoneNumberSchema }).array(),
    email: z
      .object({ value: z.string().email().max(100).or(z.literal('')) })
      .array(),
    birthdate: z.coerce
      .date()
      .max(
        timeAgo({ years: 18 }),
        'You must be at least 18 years old to use mePrism',
      )
      .min(
        timeAgo({ years: 100 }),
        'You must be under 100 years old to use mePrism',
      )
      .nullable()
      .refine((val) => val !== null, {
        message: 'Please select a valid birthdate',
      }),
    address: createAddressSchema(isStreetOptional, isZipOptional),
    mp_auth_letter_signature: z.string(),
    auth_letter_s3_key: z.string(),
    gender: z.string(),
    auth_letter_version: z.string(),
    linkedIn: isLinkedinOptional
      ? z
          .string()
          .max(120, 'LinkedIn URL cannot exceed 120 characters')
          .refine(
            (value) =>
              !value ||
              /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/.test(value),
            {
              message:
                'Invalid LinkedIn URL. Please use the format: https://www.linkedin.com/your-profile',
            },
          )
          .optional()
      : z
          .string()
          .max(120, 'LinkedIn URL cannot exceed 120 characters')
          .refine((value) => value.length > 0, {
            message: 'LinkedIn URL is required',
          })
          .refine(
            (value) =>
              !value ||
              /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/.test(value),
            {
              message:
                'Invalid LinkedIn URL. Please use the format: https://www.linkedin.com/your-profile',
            },
          ),
    agreeToTerms: agreeToTermsOptional
      ? z.boolean().refine((value) => value === true, {
          message: 'You must agree to the terms and conditions',
        })
      : z.boolean().optional(),
  })

  return profileSchema
}

export const profileSchema = z.object({
  firstName: z
    .object({
      value: z
        .string()
        .nonempty('First name is required')
        .max(50, 'Name cannot be more than 50 characters')
        .regex(
          /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
          'First name can only contain letters and hyphens (-)',
        ),
    })
    .array(),
  middleName: z
    .object({
      value: z
        .string()
        .max(50, 'Name cannot be more than 50 characters')
        .regex(
          /^(?:[a-zA-Z]+(?:[\s-][a-zA-Z]+)*)?$/,
          'Middle name can only contain letters and hyphens (-)',
        )
        .refine((value) => !(value || /^[a-zA-Z-]+$/.test(value)), {
          message: 'Middle name can only contain letters and hyphens (-)',
        }),
    })
    .array(),
  lastName: z
    .object({
      value: z
        .string()
        .max(50, 'Name cannot be more than 50 characters')
        .nonempty('Last name is required')
        .regex(
          /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
          'Last name can only contain letters and hyphens (-)',
        ),
    })
    .array(),
  otherFirstName: z
    .object({
      value: z
        .string()
        .max(50, 'Name cannot be more than 50 characters')
        .regex(
          /^$|^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
          'First name can only contain letters and hyphens (-)',
        )
        .optional(),
    })
    .array()
    .optional(),
  otherMaidenName: z
    .object({
      value: z
        .string()
        .max(50, 'Name cannot be more than 50 characters')
        .regex(
          /^$|^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
          'Maiden name can only contain letters and hyphens (-)',
        )
        .optional(),
    })
    .array()
    .optional(),
  phoneNumber: z
    .object({
      value: phoneNumberSchema,
    })
    .array(),
  email: z
    .object({
      value: z.string().email().max(100).or(z.literal('')),
    })
    .array(),
  birthdate: z.coerce
    .date()
    .max(
      timeAgo({ years: 18 }),
      'You must be at least 18 years old to use mePrism',
    )
    .min(
      timeAgo({ years: 100 }),
      'You must be under 100 years old to use mePrism',
    )
    .nullable()
    .refine((val) => val !== null, {
      message: 'Please select a valid birthdate',
    }),
  address: addressSchema.array(),
  mp_auth_letter_signature: z.string(),
  auth_letter_s3_key: z.string(),
  gender: z.string(),
  auth_letter_version: z.string(),
  linkedIn: z
    .string()
    .trim()
    .url('Invalid LinkedIn URL')
    .max(120, 'LinkedIn URL cannot exceed 120 characters')
    .regex(
      /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/,
      'LinkedIn URL must be a valid LinkedIn profile link',
    )
    .optional()
    .or(z.literal('')),
  agreeToTerms: z
    .boolean()
    .refine((value) => value === true, {
      message: 'You must agree to the terms and conditions',
    })
    .optional(),
})

export const asProfilePost = (
  schema: z.TypeOf<typeof profileSchema>,
): ProfileFields => ({
  names: {
    first: schema.firstName.map(({ value }) => value).filter(Boolean),
    middle: schema.middleName.map(({ value }) => value),
    last: schema.lastName.map(({ value }) => value).filter(Boolean),
  },
  emails: schema.email
    .map(({ value }) => value)
    .filter(Boolean)
    .map((address) => ({ address, validated: false })),
  birthdate: schema.birthdate ? schema.birthdate.toISOString() : '',
  phones: schema.phoneNumber
    .map(({ value }) => value)
    .filter(Boolean)
    .map((number) => ({ validated: false, number })),
  address: schema.address.map(({ streetAddress, zip, ...rest }) => ({
    street1: streetAddress,
    country: 'US',
    zip: zip || '',
    ...rest,
  })),
  gender: schema.gender,
  mp_auth_letter_signature: schema.mp_auth_letter_signature,
  auth_letter_s3_key: schema.auth_letter_s3_key,
  auth_letter_version: schema.auth_letter_version,
  linkedIn: schema.linkedIn,
  additional_information: {
    additional_first_name: schema.otherFirstName
      ? schema.otherFirstName
          .map(({ value }) => value)
          .filter((val): val is string => val !== undefined && val !== '')
      : [],
    maiden_name: schema.otherMaidenName
      ? schema.otherMaidenName
          .map(({ value }) => value)
          .filter((val): val is string => val !== undefined && val !== '')
      : [],
  },
})

export const fromProfileFields = (
  fields: ProfileFields,
): z.TypeOf<typeof profileSchema> => ({
  firstName:
    fields.names.first.length > 0
      ? fields.names.first.map((value) => ({ value }))
      : [{ value: '' }],
  lastName:
    fields.names.last.length > 0
      ? fields.names.last.map((value) => ({ value }))
      : [{ value: '' }],
  middleName:
    fields.names.middle.length > 0
      ? fields.names.middle.map((value: any) => ({ value }))
      : [{ value: '' }],
  otherFirstName:
    fields.additional_information &&
    fields.additional_information.additional_first_name &&
    fields.additional_information.additional_first_name.length > 0
      ? fields.additional_information.additional_first_name.map(
          (value: string) => ({ value }),
        )
      : [{ value: '' }],
  otherMaidenName:
    fields.additional_information &&
    fields.additional_information.maiden_name &&
    fields.additional_information.maiden_name.length > 0
      ? fields.additional_information.maiden_name.map((value: string) => ({
          value,
        }))
      : [{ value: '' }],
  email: fields.emails.map(({ address }) => ({ value: address })),
  phoneNumber: fields.phones.map(({ number }) => ({
    // need to strip off country code for display
    value: number.replace(/^\+1/, ''),
  })),
  birthdate: fields.birthdate ? new Date(fields.birthdate) : null,
  // below is mad because it thinks we can't convert a string to state
  // @TODO fix the type ignore
  // @ts-ignore
  address:
    fields.address.length > 0
      ? fields.address.map(({ city, state, street1: streetAddress, zip }) => ({
          city,
          state,
          streetAddress: streetAddress ?? '',
          zip: zip ?? '',
        }))
      : [{ city: '', state: '', streetAddress: '', zip: '' }],
  mp_auth_letter_signature: fields.mp_auth_letter_signature || '',
  auth_letter_s3_key: fields.auth_letter_s3_key || '',
  gender: fields.gender || '',
  auth_letter_version:
    fields.auth_letter_version || CURRENT_AUTH_LETTER_VERSION,
  linkedIn: fields.linkedIn,
})

export const emptyProfileSchema = (): z.TypeOf<typeof profileSchema> => ({
  firstName: [{ value: '' }],
  middleName: [{ value: '' }],
  lastName: [{ value: '' }],
  otherFirstName: [{ value: '' }],
  otherMaidenName: [{ value: '' }],
  address: [
    {
      city: '',
      state: '' as 'CA',
      streetAddress: '',
      zip: '',
    },
  ],
  email: [{ value: '' }],
  phoneNumber: [{ value: '' }],
  birthdate: new Date(),
  mp_auth_letter_signature: '',
  auth_letter_s3_key: '',
  gender: '',
  auth_letter_version: CURRENT_AUTH_LETTER_VERSION,
  linkedIn: '',
  agreeToTerms: false,
})
