import React, {useCallback, useEffect, useState} from 'react'
import {Box, ThemeProvider} from '@mui/material'
import {theme} from '../../../theme/DashboardTheme'
import CenteredTabs from '../../Views/Tabs'
import RemovalStats from './RemovalStats'
import OptOuts from './OptOuts'
import {useAppDispatch, useAppSelector} from '../../../redux/storeExports'
import {selectActiveGrant} from '@meprism/shared/src/redux/product/ProductSlice'
import {fetchOptOuts, selectOptOuts} from "@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice";
import Loader from "../atom/Loader";

const DashboardGrid = () => {
  const [tabValue, setTabValue] = useState('Removal Stats')
  const dispatch = useAppDispatch()
  const grant = useAppSelector(selectActiveGrant)
  const optouts = useAppSelector(selectOptOuts)
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  const [isFetching, setIsFetching] = useState(true)
  const fetchOptoutResults = useCallback(async () => {
    setIsFetching(true)
    await dispatch(fetchOptOuts())
    setIsFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchOptoutResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ToDo: We will remove this check once blast emails are sent out to all those subscribers who have purchased a grant before 2025-01-21
  const isGrantDateValid =
    !grant?.active ||
    (grant?.grant_purchase_date &&
      new Date(grant.grant_purchase_date) > new Date('2025-01-21') || optouts.length > 0)

  return (
    <Box
      sx={{
        '& .MuiCircularProgress-root': {
          color: '#1976D2',
          marginTop: 40,
        },
      }}>
      <Loader loading={isFetching}>
        <ThemeProvider theme={theme}>
          <CenteredTabs
            value={tabValue}
            handleChange={handleTabChange}
            isGrantDateValid={isGrantDateValid}
          />

          {tabValue === 'Removal Stats' ? (
            <RemovalStats grant={grant}/>
          ) : (
            <OptOuts grant={grant} optouts={optouts}/>
          )}
        </ThemeProvider>
      </Loader>
    </Box>
  )
}

export default DashboardGrid
