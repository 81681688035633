// normally this would be by environment but
// 1. I'm in a hurry here and
// 2. For this use case only prod stuff is relevant
// because other environments don't have public facing app stores
export const PROD_MEPRISM_APP_INFO = {
  params: {
    apn: 'com.meprism.android',
    isi: '1472786868',
    ibi: 'com.mePrism.client',
    ius: 'meprismproduction',
  },
  baseDynamicLinkUrl: 'https://link.meprism.com/app/',
  baseAppLinkUrl: 'https://meprism.com',
} as const