import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  useMediaQuery,
} from '@mui/material'
import MainHeader from '../shared/organism/MainHeader'
import React from 'react'
import AccSecurity from '../shared/molecule/AccSecurity'
import { theme } from '../../theme/DashboardTheme'
import { ExpandMore } from '@mui/icons-material'

export const SettingsView = () => {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container fixed maxWidth={'lg'} sx={{ marginInlineStart: '0.2rem'}}>
      <MainHeader title={'Settings'} />
      {isMobile ? (
        <div style={{ marginBlock: '1rem' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content">
              Account & Security
            </AccordionSummary>
            <AccordionDetails>
              <AccSecurity />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <AccSecurity />
      )}
    </Container>
  )
}