import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface CenteredTabsProps {
  value: string
  handleChange: (event: React.SyntheticEvent, newValue: string) => void
  isGrantDateValid: boolean | '' | undefined | null
}

export default function CenteredTabs({
  value,
  handleChange,
  isGrantDateValid,
}: CenteredTabsProps) {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      centered
      sx={{
        marginBottom: 2,
        '& .MuiTabs-indicator': {
          backgroundColor: '#9135E0', // Purple indicator
        },
        '& .MuiTab-root': {
          // Default tab style
          color: '#000000',
          fontSize: '16px',
          fontWeight: 500, // Black text for unselected tabs
          '&.Mui-selected': {
            // Selected tab style
            color: '#9135E0', // Purple text for selected tab
            fontWeight: 600,
          },
        },
      }}>
      <Tab label="Removal Stats" value={'Removal Stats'} />
      {isGrantDateValid && (
        <Tab label="Opt Out Requests" value={'Opt Out Requests'} />
      )}
    </Tabs>
  )
}
